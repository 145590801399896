import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { isInteger } from 'lodash';

@Component({
  selector: 'fuse-souhaite',
  templateUrl: './souhaite.component.html',
  styleUrls: ['./souhaite.component.scss']
})
export class SouhaiteComponent implements OnInit {
  
  step = 0;
  result = null;
  cs = [{}, {}, {}, {}, {}, {}];
  indexes = [0.5, 1, 1.5, 2, 2.5, 3];

  locked = false;

  constructor() { 
    if (location.pathname !== '/simulateurs/volume-souhaite') {
      const data = location.pathname.split('/');
      this.inputFormControl.setValue(+data[3]);

      this.simulate();
      // history.pushState({}, null, location.origin + '/simulateurs/volume-souhaite/');
    }
    else {
        this.inputFormControl.setValue(600);
    }
  }

  inputFormControl = new FormControl('', [
    Validators.compose([Validators.min(350)])
  ]);

  ngOnInit() {
    // this.inputFormControl.setValue(600);
  }

  simulate() {
    this.result = [];

    const arrays = [[], [], [], [], [], []];
    let params = [];

    this.locked ? params = [1, 2, 3] : params = [0.5, 1, 1.5, 2, 2.5, 3];
    // const params = [1, 2, 3, 0.5, 1.5, 2.5];

    const that = this;

    for (let j = 0; j < params.length; j++) {
      for (let i = 1; i <= 100; i++) {
        arrays[j].push(this.simulateByRowsAndColumns(params[j], i, 1));
      }
    }

    for (let i = 0; i < params.length; i++) {
      this.cs[i] = arrays[i].reduce((prev, curr) => {
        return (Math.abs(curr.capacity - that.inputFormControl.value) < Math.abs(prev.capacity - that.inputFormControl.value) ? curr : prev);
      });

      this.result.push(this.cs[i]);
    }

    this.result === [] ? this.nextStep() : this.setStep(1);
    history.pushState({}, null, location.origin + '/simulateurs/volume-souhaite/' + that.inputFormControl.value);

  }

  simulateByRowsAndColumns(rows: number, columns: number, juxt: number) {
    return {
      rows: rows,
      capacity: ((Math.floor(rows) * columns * 500) + (columns * 100) + (isInteger(rows) ? 0 : (columns * 250))) * juxt,
      height: isInteger(rows) ? ((rows - 1) * 1220 + 1460) : ((rows - 1.5) * 1220 + 1460 + 605),
      width: columns * 1210,
      depth: 410 * juxt,
      weight: ((Math.floor(rows) * columns * 45) + (columns * 13) + (isInteger(rows) ? 0 : (columns * 20))) * juxt, // TODO => poids du 250L
      tank: columns * Math.floor(rows) * juxt,
      tank2: isInteger(rows) ? 0 : columns * juxt,
      base: columns * juxt,
      link: '/simulateurs/configuration/' + rows + '/' + columns + '/' + juxt + '/0/0/0/0/0'
    };
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
      this.step++;
  }

  prevStep() {
      this.step--;
  }

}
