import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import {   
  AccessibilityConfig,
  Action,
  AdvancedLayout,
  ButtonEvent,
  ButtonsConfig,
  ButtonsStrategy,
  ButtonType,
  Description,
  DescriptionStrategy,
  DotsConfig,
  GalleryService,
  GridLayout,
  Image,
  ImageModalEvent,
  LineLayout,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  PreviewConfig 
} from 'angular-modal-gallery';

@Component({
  selector: 'fuse-professionnels',
  templateUrl: './professionnels.component.html',
  styleUrls: ['./professionnels.component.scss']
})
export class ProfessionnelsComponent implements OnInit{

  equiped = [];
  images: Image[];
  counter = Array;

  constructor(private galleryService: GalleryService, private sanitizer: DomSanitizer)
  {
  }

  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  returnNumber(length) {
    return new Array(length);
  }

  openGallery(id) {
    this.openImageModalRow(this.images[id]);
  }

  openImageModalRow(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.plainGalleryGrid = Object.assign({}, this.plainGalleryGrid, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

  ngOnInit(){
    
    this.images = [
      new Image(0,
        {
          img: './assets/images/installations/professionnels/1.jpg'
        }
      ),
      new Image(1,
        {
          img: './assets/images/installations/professionnels/2.jpg'
        }
      ),
      new Image(2,
        {
          img: './assets/images/installations/professionnels/3.jpg',
          description: 'Ecole de ???'
        }
      ),
      new Image(3,
        {
          img: './assets/images/installations/professionnels/4.jpg'
        }
      ),
      new Image(4,
        {
          img: './assets/images/installations/professionnels/5.jpg'
        }
      ),
      new Image(5,
        {
          img: './assets/images/installations/professionnels/6.jpg'
        }
      ),
      new Image(6,
        {
          img: './assets/images/installations/professionnels/7.jpg'
        }
      ),
      new Image(7,
        {
          img: './assets/images/installations/professionnels/8.jpg'
        }
      ),
      new Image(8,
        {
          img: './assets/images/installations/professionnels/9.jpg'
        }
      )
    ];
  }
}
