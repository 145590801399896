import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';
import { FuseSampleModule } from './main/content/sample/sample.module';
import { HomeModule } from 'app/main/content/home/home.module';
import { DescriptionModule } from 'app/main/content/produit/description/description.module';
import { CaracteristiquesModule } from 'app/main/content/produit/caracteristiques/caracteristiques.module';
import { DocumentsModule } from 'app/main/content/produit/documents/documents.module';
import { DistributeursModule } from 'app/main/content/distributeurs/distributeurs.module';
import { ParticuliersModule } from 'app/main/content/installations/particuliers/particuliers.module';
import { ProfessionnelsModule } from 'app/main/content/installations/professionnels/professionnels.module';
import { CollectivitesModule } from 'app/main/content/installations/collectivites/collectivites.module';
import { CaptableModule } from 'app/main/content/simulateurs/captable/captable.module';
import { ConfigurationModule } from 'app/main/content/simulateurs/configuration/configuration.module';
import { SouhaiteModule } from 'app/main/content/simulateurs/souhaite/souhaite.module';
import { PremontageModule } from './main/content/simulateurs/premontage/premontage.module';
import { ServiceWorkerModule } from '@angular/service-worker';

import {NgPipesModule} from 'ngx-pipes';

import { environment } from '../environments/environment';
import { EspaceModule } from './main/content/simulateurs/espace/espace.module';

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: ''
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseMainModule,

        
        FuseSampleModule,
        HomeModule,
        DescriptionModule,
        CaracteristiquesModule,
        DocumentsModule,
        DistributeursModule,
        ParticuliersModule,
        ProfessionnelsModule,
        CollectivitesModule,
        CaptableModule,
        ConfigurationModule,
        SouhaiteModule,
        PremontageModule,
        EspaceModule,

        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

        NgPipesModule
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
