import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { unescape, escape, isInteger } from 'lodash';

@Component({
  selector: 'app-espace',
  templateUrl: './espace.component.html',
  styleUrls: ['./espace.component.scss']
})
export class EspaceComponent implements OnInit {
  step = 0;
  locked = false;
  result = null;
  single = null;
  data = {};
  heightMin = 0.85;
  widthMin = 1.21;
  depthMin = 0.41;
  unit = ' m';
  max = [3, 50, 50];
  hasHalf = false;
  previewFace1 = null;
  previewFace2 = null;
  previewSide1 = null;
  previewSide2 = null;
  errorMsg = '';
  disablePanels = false;

  heightFormControl = new FormControl('', [Validators.required, Validators.compose([Validators.min(this.heightMin)])]);
  widthFormControl = new FormControl('', [Validators.required, Validators.compose([Validators.min(this.widthMin)])]);
  depthFormControl = new FormControl('', [Validators.required, Validators.compose([Validators.min(this.depthMin)])]);

  constructor(private route: ActivatedRoute, public sb: MatSnackBar) {}


  ngOnInit() {
    const par = this.route.params['_value'];

    this.heightFormControl.setValue(par.h !== undefined ? parseFloat(par.h) : this.heightMin);
    this.widthFormControl.setValue(par.l !== undefined ? parseFloat(par.l) : this.widthMin);
    this.depthFormControl.setValue(par.p !== undefined ? parseFloat(par.p) : this.depthMin);

    if (par.h !== undefined && par.l !== undefined && par.p !== undefined) {
      this.simulate();
    }

    this.heightFormControl.valueChanges.subscribe(() => {
      if (!this.heightFormControl.valid) {
        this.sb.open('Hauteur insuffisante - Minimum ' + this.heightMin + ' m', null, {duration: 3000});
        this.disablePanels = true;
      }
    });

    this.widthFormControl.valueChanges.subscribe(() => {
      if (!this.widthFormControl.valid) {
        this.sb.open('Largeur insuffisante - Minimum ' + this.widthMin + ' m', null, {duration: 3000});
        this.disablePanels = true;
      }
    });

    this.depthFormControl.valueChanges.subscribe(() => {
      if (!this.depthFormControl.valid) {
        this.sb.open('Profondeur insuffisante - Minimum ' + this.depthMin + ' m', null, {duration: 3000});
        this.disablePanels = true;
      }
    });
  } 

  simulate() {
    this.disablePanels = false;

    this.result = {
      rows: this.locked ? 1 : 0.5,
      cols: 1,
      juxt: 1
    };

    this.data = {
      height: this.heightFormControl.errors       || this.heightFormControl.value,
      width : this.widthFormControl.errors        || this.widthFormControl.value,
      depth : this.depthFormControl.errors        || this.depthFormControl.value,
    };

    this.findResult('rows', 'height', this.max[0], 1);
    this.findResult('cols', 'width', this.max[1], 1);
    this.findResult('juxt', 'depth', this.max[2], 1);
  


    this.buildPreview(this.result.rows, this.result.cols, this.result.juxt); 
    this.nextStep();
    this.result['sim'] = this.simulateByRowsAndColumns(this.result.rows, this.result.cols, this.result.juxt);
    this.single = this.simulateByRowsAndColumns(this.result.rows, this.result.cols, 1);
    
    history.pushState({}, null, location.origin + `/simulateurs/espace-disponible/${this.data['height']}/${this.data['width']}/${this.data['depth']}`);
  }

  // findResult(which, side, max, step) {
  //   // const step = this.locked ? 1 : 0.5;

  //   for (let i = step; i <= max; i += step) {
  //     let sim = this.simulateByRowsAndColumns(
  //       which === 'rows' ? i : 1,
  //       which === 'cols' ? i : 1,
  //       which === 'juxt' ? i : 1
  //     );
      
  //     sim[side] = sim[side] / 1000;
  //     // console.log(sim[side], this.data[side]);

  //     if (sim[side] < this.data[side]) {

  //       which === 'rows' ? 
  //         (max < 3 ? max++ : this.result['rows'] = max) :
  //         max++;
  //     }

  //     if (i === 1 && this.data[side] < sim[side]) {
  //       this.result[which] = 0;
  //       break;
  //     }

  //     if (
  //       sim[side] <= this.data[side] &&
  //       this.simulateByRowsAndColumns(
  //         which === 'rows' ? i + 1 : 1,
  //         which === 'cols' ? i + 1 : 1,
  //         which === 'juxt' ? i + 1 : 1)[side] / 1000 >= this.data[side]
  //     ) {
  //       this.result[which] = i;
  //       console.log(sim[side], this.data[side], max, which, this.result[which]);
  //       break;
  //     }
  //   }
  // }

  // findResult(which, side, max, fullStep, halfStep) {
  //   for (let i = halfStep; i <= max; i += (this.isHalfStepApplicable(which) ? halfStep : fullStep)) {
  //     let sim = this.simulateByRowsAndColumns(
  //       which === 'rows' ? i : 1,
  //       which === 'cols' ? i : 1,
  //       which === 'juxt' ? i : 1
  //     );
      
  //     sim[side] = sim[side] / 1000;

  //     if (sim[side] < this.data[side]) {
  //       which === 'rows' ? 
  //         (max < 3 ? max++ : this.result['rows'] = max) :
  //         max++;
  //     }

  //     if (i === 1 && this.data[side] < sim[side]) {
  //       this.result[which] = 0;
  //       break;
  //     }

  //     if (
  //       sim[side] <= this.data[side] &&
  //       this.simulateByRowsAndColumns(
  //         which === 'rows' ? i + 1 : 1,
  //         which === 'cols' ? i + 1 : 1,
  //         which === 'juxt' ? i + 1 : 1)[side] / 1000 >= this.data[side]
  //     ) {
  //       this.result[which] = i;
  //       console.log(sim[side], this.data[side], max, which, this.result[which]);
  //       break;
  //     }
  //   }
  // }

  // isHalfStepApplicable(which: string): boolean {
  //   return which === 'rows';
  // }

  findResult(which, side, max, step) {
    let halfStep = step / 2;
    for (let i = step; i <= max; i += (which === 'rows' && i != max ? halfStep : step)) {
      let sim = this.simulateByRowsAndColumns(
        which === 'rows' ? i : 1,
        which === 'cols' ? i : 1,
        which === 'juxt' ? i : 1
      );

      sim[side] = sim[side] / 1000;

      if (sim[side] <= this.data[side]) {
        this.result[which] = i;
      } else {
        break;
      }
    }
  }


  simulateByRowsAndColumns(rows: number, columns: number, juxt: number) {
    return {
      capacity: ((Math.floor(rows) * columns * 500) + (columns * 100) + (this.isInt(rows) ? 0 : (columns * 250))) * juxt,
      height: this.isInt(rows) ? ((rows - 1) * 1220 + 1460) : ((rows - 1.5) * 1220 + 1460 + 605),
      width: columns * 1210,
      depth: 410 * juxt,
      weight: ((Math.floor(rows) * columns * 45) + (columns * 13) + (this.isInt(rows) ? 0 : (columns * 20))) * juxt, // TODO => poids du 250L
      link: '/simulateurs/configuration/' + rows + '/' + columns + '/' + juxt + '/1/0/0/0/0'
    };
  }

  isInt(value) {
    let x;
    // tslint:disable-next-line:no-bitwise
    return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  buildPreview(rows: number, columns: number, juxt: number) {
    this.hasHalf = !isInteger(rows);
    this.previewFace1 = buildPreview(rows, columns);
    this.previewSide1 = buildPreview(rows, juxt);

    this.previewFace2 = buildPreview(rows, columns);
    this.previewSide2 = buildPreview(rows, 1);
  }
}

function buildPreview(rows: number, by: number) {
  let tmp = []; 

  for (let i = 1; i <= by; i++) {
      let result = [];

      for (let j = 0.5; j <= rows; j++) {
          if (rows === 0.5) { result.push(250); }

          if (j === rows && !isInteger(rows) && rows > 0.5) { result.push(250); }

          if (j !== rows) { result.push(500); }

          if (result.indexOf(100) < 0) { result.push(100); }

          result = result.sort((n1, n2) => n2 - n1);
          if (result.indexOf(250) > -1) {
              result.unshift(result.splice(result.indexOf(250), 1)[0]);
          }
      }

      tmp.push(result);
  }

  return tmp;
}
