export const navigation = [
    {
        'title'   : 'Accueil',
        'type'    : 'item',
        'url' : '/home',
        'icon': 'meeting_room'
    },
    {
        'title'   : 'Le Produit',
        'type'    : 'group',
        'icon' : 'description',
        'children': [
            {
                'title'   : 'Description',
                'type'    : 'item',
                'url' : '/produit/description',
                'icon' : 'subject',
            },
            {
                'title'   : 'Caractéristiques',
                'type'    : 'item',
                'url' : '/produit/caracteristiques',
                'icon' : 'ballot',
            },
            {
                'title'   : 'Téléchargements',
                'type'    : 'item',
                'url' : '/produit/documents',
                'icon': 'file_download'
            }
        ]
    },
    {
        'title'   : 'Installations',
        'type'    : 'group',
        'icon' : 'camera_alt',
        'children': [
            {
                'title'   : 'Particuliers',
                'type'    : 'item',
                'url' : '/installations/particuliers',
                'icon' : 'home',
            },
            {
                'title'   : 'Professionnels',
                'type'    : 'item',
                'url' : '/installations/professionnels',
                'icon' : 'business',
            },
            {
                'title'   : 'Collectivités',
                'type'    : 'item',
                'url' : '/installations/collectivites',
                'icon' : 'location_city',
            }
        ]
    },
    {
        'title'   : 'Simulateurs',
        'type'    : 'group',
        'icon' : 'settings_input_component',
        'children': [
            {
                'title'   : 'Configuration',
                'type'    : 'item',
                'url' : '/simulateurs/configuration',
                'icon' : 'build',
            },
            {
                'title'   : 'Volume souhaité',
                'type'    : 'item',
                'url' : '/simulateurs/volume-souhaite',
                'icon' : 'settings_input_component',
            },
            {
                'title'   : 'Volume captable (bientôt)',
                'type'    : 'item',
                // 'url' : '/simulateurs/volume-captable',
            },
        ]
    }
];
