import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptableComponent } from './captable.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
// tslint:disable-next-line:max-line-length
import { MatCardModule, MatStepperModule, MatSelectModule, MatInputModule, MatTableModule, MatListModule, MatDividerModule, MatButtonModule, MatCheckboxModule, MatSnackBarModule, MatExpansionModule, MatIconModule, MatTooltipModule, MatDialogModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

const routes = [
  {
      path     : 'simulateurs/volume-recommande',
      component: CaptableComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),

    MatInputModule,
    MatTableModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    ClipboardModule,
    MatDialogModule,
    MatStepperModule,
    MatSelectModule,
    MatCardModule
  ],
  declarations: [CaptableComponent],
  exports: [CaptableComponent]
})
export class CaptableModule { }
