import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremontageComponent } from './premontage.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule, MatInputModule, MatListModule, MatDividerModule, MatButtonModule, MatSnackBarModule, MatExpansionModule, MatTooltipModule } from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';

const routes = [
  {
    path        : 'simulateurs/pre-montage/:r/:c/:j/:g',
    component   : PremontageComponent
  },
  {
    path        : 'simulateurs/pre-montage/:r/:c/:j/:g/:rob/:van/:col',
    component   : PremontageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,

    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    CommonModule,
    MatInputModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTooltipModule,
    MatRadioModule,
    MatCardModule
  ],
  declarations: [PremontageComponent],
  exports: [PremontageComponent]
})
export class PremontageModule { }
