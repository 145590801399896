import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector     : 'fuse-quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseQuickPanelComponent implements OnInit
{
    formSent = false;
    contactFormGroup: FormGroup;

    constructor(private _formBuilder: FormBuilder)
    {
        
    }

    ngOnInit()
    {
        this.contactFormGroup = this._formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            address: ['', Validators.compose([Validators.required])],
            zip: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{5}')])],
            city: ['', Validators.compose([Validators.required])],
            tel: ['', Validators.compose([Validators.pattern('[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}')])],
            email: ['', Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])],
            object: ['', Validators.compose([Validators.required])],
            message: ['', Validators.compose([Validators.required])],
            link: ['']
        });
    }

    sendForm() {
        const form = <HTMLFormElement>document.getElementById('formspree');
        form.submit();
        this.formSent = true;
    }

}
