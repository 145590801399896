import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fuse-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  downloadFile (target: string) {
    switch (target) {
      case 'plaquette-hd': {
        window.location.href = 'http://murdeau.bzh/public/plaquette-murdeau-HD.pdf';
        break;
      }
      case 'plaquette-ld': {
        window.location.href = 'http://murdeau.bzh/public/plaquette-murdeau-LD.pdf';
        break;
      }
      case 'notice': {
        window.location.href = 'http://murdeau.bzh/public/notice-murdeau-FR.pdf';
        break;
      }
      case 'capteur': {
        window.location.href = 'http://murdeau.bzh/public/capteur-niveau.pdf';
        break;
      }
      case 'insa': {
        window.location.href = 'http://murdeau.bzh/public/insa-murdeau.pdf';
        break;
      }
      case 'garantie': {
        window.location.href = 'http://murdeau.bzh/public/garantie-murdeau-FR.pdf';
        break;
      }
      case '3P': {
        window.location.href = 'http://murdeau.bzh/public/fiche-technique-3P.pdf';
        break;
      }
      case 'not-3P': {
        window.location.href = 'http://murdeau.bzh/public/montage-3P.pdf';
        break;
      }
      case 'vertimax': {
        window.location.href = 'http://murdeau.bzh/public/vertimax-fiche-tech.pdf';
        break;
      }
      case 'dn125': {
        window.location.href = 'http://murdeau.bzh/public/notice-dn125.pdf';
        break;
      }
      case 'dn50': {
        window.location.href = 'http://murdeau.bzh/public/notice-dn50.pdf';
        break;
      }
      case 'joints': {
        window.location.href = 'http://murdeau.bzh/public/pose-joints.pdf';
        break;
      }
    }
  }
}
