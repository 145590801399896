import { NgModule } from '@angular/core';
import { ProfessionnelsComponent } from './professionnels.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatGridListModule } from '@angular/material';
import 'hammerjs';
import 'mousetrap';
import { ModalGalleryModule } from 'angular-modal-gallery';

const routes = [{
  path: 'installations/professionnels',
  component: ProfessionnelsComponent
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ModalGalleryModule.forRoot(),
    FuseSharedModule,
    MatGridListModule
  ],
  declarations: [ProfessionnelsComponent],
  exports: [ProfessionnelsComponent]
})
export class ProfessionnelsModule { }
