import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { isInteger } from 'lodash';
import * as $ from 'jquery';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-premontage',
  templateUrl: './premontage.component.html',
  styleUrls: ['./premontage.component.scss']
})
export class PremontageComponent implements OnInit {
  result = null;
  hasHalf = false;
  previewFace = null;
  previewSide = null;
  selectedRobCount = 0;
  link = `/simulateurs/pre-montage/`;
  origin = location.origin;

  acc = ['rob', 'van', 'col'];
  face = ['F', 'L', 'R'];
  side = ['L', 'M', 'R'];

  constructor(public sb: MatSnackBar, private cb: ClipboardService, private route: ActivatedRoute) {}

  ngOnInit() {

    $('.demo').prop('checked', true);

    const par = this.route.params['_value'];

    // console.log(this.route.params['_value']);

    this.link += `${par.r}/${par.c}/${par.j}/${par.g}/${par.rob}/${par.van}/${par.col}`;

    this.result = this.simulateByRowsAndColumns(
      par.r.split('.').length ? parseFloat(par.r) : parseInt(par.r, 0), parseInt(par.c, 0), parseInt(par.j, 0)
    );
    this.buildPreview(
      par.r.split('.').length ? parseFloat(par.r) : parseInt(par.r, 0), parseInt(par.c, 0), parseInt(par.j, 0)
    );

    setTimeout(() => {
      this.select(this.intToPos(this.link.split('/')[this.link.split('/').indexOf('pre-montage') + 5]));
      this.select(this.intToPos(this.link.split('/')[this.link.split('/').indexOf('pre-montage') + 6]));
      this.select(this.intToPos(this.link.split('/')[this.link.split('/').indexOf('pre-montage') + 7]));
    }, 100);
  }

  posToInt(pos) {
    const data = pos.split('-');

    return '' + this.acc.indexOf(data[0]) + this.face.indexOf(data[1]) + this.side.indexOf(data[2]) + data[3];
  }

  intToPos(int) {
    const data = int.split('');

    return '' + this.acc[data[0]] + '-' + this.face[data[1]] + '-'  + this.side[data[2]] + '-'  + data[3];
  }

  copyToClipboard() {
    this.cb.copyFromContent(location.origin + this.link);
    this.sb.open('Lien copié !', null, {duration: 2000});
  }

  isFirstLevel(value) {
    return (value === 1 || value === 0.5) ? true : false;
  }

  isInt(value) {
    let x;
    // tslint:disable-next-line:no-bitwise
    return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
  }

  simulateByRowsAndColumns(rows: number, columns: number, juxt: number) {
    return {
      rows: rows,
      capacity: ((Math.floor(rows) * columns * 500) + (columns * 100) + (isInteger(rows) ? 0 : (columns * 250))) * juxt,
      height: isInteger(rows) ? ((rows - 1) * 1220 + 1460) : ((rows - 1.5) * 1220 + 1460 + 605),
      width: columns * 1210,
      depth: 410 * juxt,
      weight: ((Math.floor(rows) * columns * 45) + (columns * 13) + (isInteger(rows) ? 0 : (columns * 20))) * juxt, // TODO => poids du 250L
      tank: columns * Math.floor(rows) * juxt,
      tank2: isInteger(rows) ? 0 : columns * juxt,
      base: columns * juxt,
      link: '/simulateurs/configuration/' + rows + '/' + columns + '/' + juxt + '/0/0/0/0/0'
    };
  }

  buildPreview(rows: number, columns: number, juxt: number) {
    this.hasHalf = !isInteger(rows);
    this.previewFace = buildPreview(rows, columns);
    this.previewSide = buildPreview(rows, juxt);
  }

  select(event) {
    const elems = $(`.radio-${event.split('-')[0]}`);
    
    elems.each((i, elem) => {
      $(elem).prop('checked', $(elem).attr('id') === event);

      const split = event.split('-');
      const spot = this.link.split('/');
      const theInt = spot[spot.indexOf('pre-montage') + this.acc.indexOf(split[0]) + 5];
      this.link = this.link.replace(theInt, this.posToInt(event));
    });

    history.pushState({}, null, location.origin + this.link);
  }

  // Open print window and set it to landscape with 80% scale
  print() {
    window.print();
  }
}

function buildPreview(rows: number, by: number) {
  let tmp = []; 

  for (let i = 1; i <= by; i++) {
      let result = [];

      for (let j = 0.5; j <= rows; j++) {
          if (rows === 0.5) {
              result.push(250);
          }

          if (j === rows && !isInteger(rows) && rows > 0.5) {
              result.push(250);
          }

          if (j !== rows) {
              result.push(500);
          }

          if (result.indexOf(100) < 0) {
              result.push(100);
          }

          result = result.sort((n1, n2) => n2 - n1);
          if (result.indexOf(250) > -1) {
              result.unshift(result.splice(result.indexOf(250), 1)[0]);
          }
      }

      tmp.push(result);
  }

  return tmp;
}
