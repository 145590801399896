import { Component, Input, OnInit } from '@angular/core';
import { d } from '@angular/core/src/render3';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { unescape, escape, isInteger, result } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'fuse-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {
    step = 0;
    data = {};
    link = '';
    premountLink = '';
    orderLink = 'https://murdeau.bzh/configurateur?conf=';
    premount = false;
    potable = false;
    hasError = false;
    hasHalf = false;
    result = null;
    previewFace = null;
    previewSide = null;
    previewTop = null;

    locked = false;

    constructor(public sb: MatSnackBar, public dialog: MatDialog, private cb: ClipboardService)
    {
        if (location.pathname !== '/simulateurs/configuration') {
            const data = location.pathname.split('/');

            // console.log(data[3], typeof(+data[3]));

            // data[3].toString().indexOf(',') > 0 ? parseFloat(data[3]) : console.log(data[3]);

            // +data[3].toString().indexOf(',') > 0 ? Number(+data[3].replace(',', '.')) : data[3] = data[3];

            this.rowsFormControl.setValue(parseFloat(data[3]));
            this.columnsFormControl.setValue(+data[4]);
            this.juxtFormControl.setValue(+data[5]);
            this.colgFormControl.setValue(+data[6]);
            this.robsFormControl.setValue(+data[7]);
            this.cptFormControl.setValue(+data[8]);
            +data[9] === 0 ? this.premount = false : this.premount = true;
            +data[10] === 0 ? this.potable = false : this.potable = true;

            this.simulate();
            // history.pushState({}, null, location.origin + '/simulateurs/configuration');
        }
        else {
            this.rowsFormControl.setValue(1);
            this.columnsFormControl.setValue(1);
            this.juxtFormControl.setValue(1);
            this.colgFormControl.setValue(0);
            this.robsFormControl.setValue(0);
            this.cptFormControl.setValue(0);
            this.premount = false;
            this.potable = false;
        }
    }

    rowsFormControl = new FormControl('', [
        Validators.compose([Validators.required, Validators.min(0.5), Validators.max(3)])
    ]);

    columnsFormControl = new FormControl('', [
        Validators.compose([Validators.required, Validators.min(1)])
    ]);

    juxtFormControl = new FormControl('', [
        Validators.compose([Validators.required, Validators.min(1)])
    ]);

    colgFormControl = new FormControl('', [
        Validators.compose([Validators.min(0)])
    ]);

    robsFormControl = new FormControl('', [
        Validators.compose([Validators.min(0)])
    ]);

    cptFormControl = new FormControl('', [
        Validators.compose([Validators.min(0)])
    ]);

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

   
    simulate() {
        this.result = null;
        this.previewFace = {};
        this.previewSide = {};
        this.previewTop = {};
        this.hasError = false;

        this.data = {
            rows: this.rowsFormControl.errors       || this.rowsFormControl.value,
            columns: this.columnsFormControl.errors || this.columnsFormControl.value,
            juxt: this.juxtFormControl.errors       || this.juxtFormControl.value,
            colg: this.colgFormControl.errors       || this.colgFormControl.value,
            robs: this.robsFormControl.errors       || this.robsFormControl.value,
            cpt: this.cptFormControl.errors         || this.cptFormControl.value
        };

        if (this.data['colg'] === '') {
            this.data['colg'] = 0;
        }

        if (this.data['robs'] === '') {
            this.data['robs'] = 0;
        }

        if (this.data['cpt'] === '') {
            this.data['cpt'] = 0;
        }

        // tslint:disable-next-line:prefer-const
        for (let i in this.data) {
            if (typeof this.data[i] === 'object' || this.data[i] === null) {
                this.sb.open('Le formulaire comporte des erreurs', null, {duration: 2000});
                this.hasError = true;
                break;
            }
        }

        if (!this.hasError) {
            this.result = this.simulateByRowsAndColumns(this.data['rows'], this.data['columns'], this.data['juxt'], this.data['colg'], this.data['robs']);
            this.buildPreview(this.data['rows'], this.data['columns'], this.data['juxt']);
            this.generateLink();
            this.result === null ? this.nextStep() : this.setStep(2);

        }
    }

    simulateByRowsAndColumns(rows: number, columns: number, juxt: number, colg: number, robs: number) {

        return {
            capacity: ((Math.floor(rows) * columns * 500) + (columns * 100) + (this.isInt(rows) ? 0 : (columns * 250))) * juxt,
            height: this.isInt(rows) ? ((rows - 1) * 1220 + 1460) : ((rows - 1.5) * 1220 + 1460 + 605),
            width: columns * 1210,
            depth: 410 * juxt,
            weight: ((Math.floor(rows) * columns * 45) + (columns * 13) + (this.isInt(rows) ? 0 : (columns * 20))) * juxt,
            tank: columns * Math.floor(rows) * juxt,
            tank2: this.isInt(rows) ? 0 : columns * juxt,
            base: columns * juxt,
            colg: colg,
            dn125: columns * Math.ceil(rows) * juxt,
            dn50: ((columns * juxt) - 1) * 2 + colg,
            security: columns * juxt,
            drain: 1,
            grille: columns * juxt,
            tap: 1 + robs,
            screws: 4 * columns * Math.ceil(rows),
            surface: Math.round(((columns * 1210 * 410 * juxt / 1000 / 1000) + Number.EPSILON) * 100) / 100,
            kj: (columns - 1) * juxt + juxt - 1
        };
    }

    isInt(value) {
        let x;
        // tslint:disable-next-line:no-bitwise
        return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
    }

    buildPreview(rows: number, columns: number, juxt: number) {
        this.hasHalf = !this.isInt(rows);
        this.previewFace    = buildPreview(rows, columns);
        this.previewSide    = buildPreview(rows, juxt);
        this.previewTop     = buildTopPreview(columns, juxt);
    }

    generateLink() {
        this.link = location.host + '/simulateurs/configuration';

        let tmpPremount;
        let tmpPotable;
        this.premount ? tmpPremount = 1 : tmpPremount = 0;
        this.potable ? tmpPotable = 1 : tmpPotable = 0;
        // tslint:disable-next-line:max-line-length
        // let params = `?rows=${this.data.rows}?cols=${this.data.columns}?juxt=${this.data.juxt}?colg=${this.data.colg}?robs=${this.data.robs}?kits=${this.cptFormControl.value}?premount=${this.premount}`;
        const newData: any = this.data;
        const params = `/${newData.rows}/${newData.columns}/${newData.juxt}/${newData.colg}/${newData.robs}/${newData.cpt}/${tmpPremount}/${tmpPotable}`;
        this.link += params;
        // tslint:disable-next-line:max-line-length
        this.premountLink = `/simulateurs/pre-montage/${newData.rows}/${newData.columns}/${newData.juxt}/${newData.colg}/0120/1100/2110`;
        
        history.pushState({}, null, location.origin + '/simulateurs/configuration' + params);
    }

    order() {
        const newData: any = this.data;
        if (newData.columns <= 5 && newData.juxt <= 5) {
            this.orderLink = `https://murdeau.bzh/configurateur?conf=${newData.rows}-${newData.columns}-${newData.juxt}`;
            location.href = this.orderLink;
        }
        else {
            let tmpPremount;
            let tmpPotable;
            this.premount ? tmpPremount = 1 : tmpPremount = 0;
            this.potable ? tmpPotable = 1 : tmpPotable = 0;
            // tslint:disable-next-line:max-line-length
            this.orderLink = `https://murdeau.bzh/contact?conf="https://sim.murdeau.bzh/simulateurs/configuration/${newData.rows}/${newData.columns}/${newData.juxt}/${newData.colg}/${newData.robs}/${newData.cpt}/${tmpPremount}/${tmpPotable}"`;
            location.href = this.orderLink;
        }
    }

    copyToClipboard() {
        this.cb.copyFromContent(this.link);
        this.sb.open('Lien copié !', null, {duration: 2000});
    }

    goToPremount() {
        const newData: any = this.data;
        // tslint:disable-next-line:max-line-length
        location.href = `${location.origin}/simulateurs/pre-montage/${newData.rows}/${newData.columns}/${newData.juxt}/${newData.colg}/rob=L-R-0/van=L-L-0/col=R-R-${newData.rows}`;
    }

}

function buildTopPreview(columns: number, by: number) {
    // tslint:disable-next-line:prefer-const
    let tmp = [];

    for (let i = 1; i <= columns; i++) {
        // tslint:disable-next-line:prefer-const
        // tslint:disable-next-line:no-shadowed-variable
        let result = [];

        for (let j = 1; j <= by; j++) {
            result.push(100);
        }

        result = result.sort((n1, n2) => n2 - n1);
        tmp.push(result);
    }

    console.log(tmp);

    return tmp;
}

function buildPreview(rows: number, by: number) {
    // tslint:disable-next-line:prefer-const
    let tmp = [];

    for (let i = 1; i <= by; i++) {
        // tslint:disable-next-line:no-shadowed-variable
        let result = [];

        for (let j = 0.5; j <= rows; j++) {
            if (rows === 0.5) {
                result.push(250);
            }

            if (j === rows && !isInteger(rows) && rows > 0.5) {
                result.push(250);
            }

            if (j !== rows) {
                result.push(500);
            }

            if (result.indexOf(100) < 0) {
                result.push(100);
            }

            result = result.sort((n1, n2) => n2 - n1);
            if (result.indexOf(250) > -1) {
                result.unshift(result.splice(result.indexOf(250), 1)[0]);
            }
        }

        tmp.push(result);
    }

    return tmp;
}

function utf8_to_b64( str ) {
    return window.btoa(unescape(encodeURIComponent(str)));
}
  
function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob(str)));
}
