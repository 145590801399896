import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { JSONService } from 'app/services/jsonservice.service';

@Component({
  selector: 'fuse-distributeurs',
  templateUrl: './distributeurs.component.html',
  styleUrls: ['./distributeurs.component.scss']
})
export class DistributeursComponent implements OnInit {

  lat = 46.9044413;
  lng = 2.7655006;
  zoom = 6.32;

  markers = [];
  zones = [];
  previous: { close: () => void; };

  constructor(private http: HttpClient, private json: JSONService) {

  }

  clickedMarker(infowindow: any) {
    if (this.previous) {
        this.previous.close();
    }
    this.previous = infowindow;
 }

  setCenter(where: any) {
    switch (where) {
      case 'fr': {
        this.lat = 46.9044413;
        this.lng = 2.7655006;
        this.zoom = 6.32;
        break;
      }
      case 'su': {
        this.lat = 46.791695;
        this.lng = 8.1463677;
        this.zoom = 8.38;
        break;
      }
      case 'be': {
        this.lat = 50.6631298;
        this.lng = 4.3283951;
        this.zoom = 8;
        break;
      }
      case 'ma': {
        this.lat = 14.6353585;
        this.lng = -61.0936598;
        this.zoom = 10.9;
        break;
      }
      case 'ga': {
        this.lat = 16.1725424;
        this.lng = -61.5369453;
        this.zoom = 10.28;
        break;
      }
    }
  }

  ngOnInit() {
    this.json.getJSON().subscribe(data => {
      this.markers = data;
    });
  }

}
