import { NgModule } from '@angular/core';
import { CollectivitesComponent } from './collectivites.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatGridListModule, MatListModule } from '@angular/material';
import 'hammerjs';
import 'mousetrap';
import { ModalGalleryModule } from 'angular-modal-gallery';

const routes = [{
  path: 'installations/collectivites',
  component: CollectivitesComponent
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ModalGalleryModule.forRoot(),
    FuseSharedModule,
    MatGridListModule,
    MatListModule
  ],
  declarations: [CollectivitesComponent],
  exports: [CollectivitesComponent]
})
export class CollectivitesModule { }
