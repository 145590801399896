import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationComponent } from './configuration.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
// tslint:disable-next-line:max-line-length
import { MatInputModule, MatTableModule, MatListModule, MatDividerModule, MatButtonModule, MatCheckboxModule, MatSnackBarModule, MatExpansionModule, MatIconModule, MatTooltipModule, MatDialogModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

const routes = [
  {
    path      : 'simulateurs/configuration',
    component : ConfigurationComponent
  },
  {
    path      : 'simulateurs/configuration/:r/:c/:j/:co/:ro/:cpt/:p/:po',
    component : ConfigurationComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),

    MatInputModule,
    MatTableModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    ClipboardModule,
    MatDialogModule
  ],
  declarations: [ConfigurationComponent],
  exports: [ConfigurationComponent]
})
export class ConfigurationModule { }
