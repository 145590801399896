import { NgModule } from '@angular/core';
import { DocumentsComponent } from './documents.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatListModule, MatIconModule, MatButtonModule, MatTooltipModule } from '@angular/material';

const routes = [
  {
    path: 'produit/documents',
    component: DocumentsComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  declarations: [DocumentsComponent],
  exports: [DocumentsComponent]
})
export class DocumentsModule { }
