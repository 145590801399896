import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { DescriptionComponent } from './description.component';
import { MatIconModule, MatButtonModule, MatListModule } from '@angular/material';
import { FuseWidgetModule } from '@fuse/components';

const routes = [
  {
      path     : 'produit/description',
      component: DescriptionComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,

    FuseWidgetModule,
    MatIconModule,
    MatButtonModule,
    MatListModule
  ],
  declarations: [DescriptionComponent],
  exports: [DescriptionComponent]
})
export class DescriptionModule { }
