import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import {   
  AccessibilityConfig,
  Action,
  AdvancedLayout,
  ButtonEvent,
  ButtonsConfig,
  ButtonsStrategy,
  ButtonType,
  Description,
  DescriptionStrategy,
  DotsConfig,
  GalleryService,
  GridLayout,
  Image,
  ImageModalEvent,
  LineLayout,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  PreviewConfig 
} from 'angular-modal-gallery';

@Component({
  selector: 'fuse-collectivites',
  templateUrl: './collectivites.component.html',
  styleUrls: ['./collectivites.component.scss']
})
export class CollectivitesComponent implements OnInit{

  equiped = [];
  images: Image[];
  counter = Array;

  constructor(private galleryService: GalleryService, private sanitizer: DomSanitizer)
  {
  }

  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  returnNumber(length) {
    return new Array(length);
  }

  openGallery(id) {
    this.openImageModalRow(this.images[id]);
  }

  openImageModalRow(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.plainGalleryGrid = Object.assign({}, this.plainGalleryGrid, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

  ngOnInit(){
    
    this.images = [
      new Image(0,
        {
          img: './assets/images/installations/collectivites/1.jpg'
        }
      ),
      new Image(1,
        {
          img: './assets/images/installations/collectivites/2.jpg'
        }
      ),
      new Image(2,
        {
          img: './assets/images/installations/collectivites/3.jpg'
        }
      ),
      new Image(3,
        {
          img: './assets/images/installations/collectivites/4.jpg'
        }
      ),
      new Image(4,
        {
          img: './assets/images/installations/collectivites/5.jpg'
        }
      ),
      new Image(5,
        {
          img: './assets/images/installations/collectivites/6.jpg'
        }
      ),
      new Image(6,
        {
          img: './assets/images/installations/collectivites/7.jpg'
        }
      ),
      new Image(7,
        {
          img: './assets/images/installations/collectivites/8.jpg'
        }
      ),
      new Image(8,
        {
          img: './assets/images/installations/collectivites/9.jpg'
        }
      ),
      new Image(9,
        {
          img: './assets/images/installations/collectivites/10.jpg'
        }
      ),
      new Image(10,
        {
          img: './assets/images/installations/collectivites/11.jpg'
        }
      ),
      new Image(11,
        {
          img: './assets/images/installations/collectivites/12.jpg'
        }
      ),
    ];

    this.equiped = [
      {name: 'Principauté de Monaco', where: '96 000 Litres le long d\'un parking'},
      {name: 'Direction de l\'Aménagement Urbain', where: 'Monaco'},
      {name: 'Institut de Genech', where: 'dept. 59'},
      {name: 'Mairie de Gonfreville-l\'Orcher', where: 'dept. 76'},
      {name: 'De nombreuses cours d\'écoles parisiennes', where: 'Programme OASIS'},
      {name: 'Mairie de Le Cannet des Maures', where: 'dept. 83'},
      {name: 'Centre Technique Municipal de Saint-Priest', where: 'dept. 69'},
      {name: 'Ecole Léon Lagarde', where: 'dept. 34'},
      {name: 'Ecole primaire du Cannet-des-Maures', where: 'dept. 83'},
      {name: 'Ecole d\'Abeilhans', where: 'dept. 34'},
      {name: 'Mairie de Menetrol', where: 'dept. 63'},
      {name: 'Mairie de Cosnac', where: 'dept. 19'},
      {name: 'Mairie de Tournus', where: 'dept. 71'},
      {name: 'Mairie de Dardilly', where: 'dept. 69'},
      {name: 'Mairie de Veneux-les-Sablons', where: 'dept. 77'},
      {name: 'Mairie de Montmorency', where: 'dept. 95'},
      {name: 'Serres Municipales', where: 'dept. 44'},
      {name: 'Mairie d\'Oullins', where: 'Parc de Sanzy dept. 69'},
      {name: 'Mairie de la Motte Servolex', where: 'Parc de Sanzy dept. 73'},
      {name: 'Mairie de Fleury-les Aubrais', where: 'Parc de Sanzy dept. 45'},
      {name: 'Mairie de Vénissieux', where: 'dept. 69'},
      {name: 'Mairie d\'Ermont', where: 'dept. 95'},
      {name: 'Mairie de Bois Colombes', where: 'dept. 92'},
      {name: 'Mairie de l\'Étang-la-Ville', where: 'dept. 78'},
      {name: 'Service technique de Marolles en Brie', where: 'dept. 94'},
      {name: 'Laboratoire de Physique Subatomique', where: 'Université Grenoble Alpes'},
      {name: 'Entreprise Abri Services', where: 'dept. 44'},
      {name: 'Jardins partagés', where: 'Sur toute la France'}
    ];
  }

}
