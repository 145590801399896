import { NgModule } from '@angular/core';
import { DistributeursComponent } from './distributeurs.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { MatButtonModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';

const routes = [{
  path: 'distributeurs',
  component: DistributeursComponent
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule,
    BrowserModule,
    MatButtonModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA0xYjul26Jz7Ll3_rSvwhxtgeUk_tkax8'
    })
  ],
  declarations: [DistributeursComponent],
  exports: [DistributeursComponent]
})
export class DistributeursModule { }
