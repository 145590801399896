import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import {   
  AccessibilityConfig,
  Action,
  AdvancedLayout,
  ButtonEvent,
  ButtonsConfig,
  ButtonsStrategy,
  ButtonType,
  Description,
  DescriptionStrategy,
  DotsConfig,
  GalleryService,
  GridLayout,
  Image,
  ImageModalEvent,
  LineLayout,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  PreviewConfig 
} from 'angular-modal-gallery';

@Component({
  selector: 'fuse-particuliers',
  templateUrl: './particuliers.component.html',
  styleUrls: ['./particuliers.component.scss']
})

export class ParticuliersComponent implements OnInit {

  images: Image[];
  counter = Array;

  constructor(private galleryService: GalleryService, private sanitizer: DomSanitizer)
  {
  }

  plainGalleryGrid: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  returnNumber(length) {
    return new Array(length);
  }

  openGallery(id) {
    this.openImageModalRow(this.images[id]);
  }

  openImageModalRow(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.plainGalleryGrid = Object.assign({}, this.plainGalleryGrid, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

  ngOnInit(){
    
    this.images = [
      new Image(0,
        {
          img: './assets/images/installations/particuliers/1.jpg'
        }
      ),
      new Image(1,
        {
          img: './assets/images/installations/particuliers/2.jpg'
        }
      ),
      new Image(2,
        {
          img: './assets/images/installations/particuliers/3.jpg'
        }
      ),
      new Image(3,
        {
          img: './assets/images/installations/particuliers/4.jpg'
        }
      ),
      new Image(4,
        {
          img: './assets/images/installations/particuliers/5.jpg'
        }
      ),
      new Image(5,
        {
          img: './assets/images/installations/particuliers/6.jpg'
        }
      ),
      new Image(6,
        {
          img: './assets/images/installations/particuliers/7.jpg'
        }
      ),
      new Image(7,
        {
          img: './assets/images/installations/particuliers/8.jpg'
        }
      ),
      new Image(8,
        {
          img: './assets/images/installations/particuliers/9.jpg'
        }
      ),
      new Image(9,
        {
          img: './assets/images/installations/particuliers/10.jpg'
        }
      ),
      new Image(10,
        {
          img: './assets/images/installations/particuliers/11.jpg'
        }
      ),
      new Image(11,
        {
          img: './assets/images/installations/particuliers/12.jpg'
        }
      ),
      new Image(12,
        {
          img: './assets/images/installations/particuliers/13.jpg'
        }
      ),
      new Image(13,
        {
          img: './assets/images/installations/particuliers/14.jpg'
        }
      ),
      new Image(14,
        {
          img: './assets/images/installations/particuliers/15.jpg'
        }
      ),
      new Image(15,
        {
          img: './assets/images/installations/particuliers/16.jpg'
        }
      ),
      new Image(16,
        {
          img: './assets/images/installations/particuliers/17.jpg'
        }
      ),
      new Image(17,
        {
          img: './assets/images/installations/particuliers/18.jpg'
        }
      ),
      new Image(18,
        {
          img: './assets/images/installations/particuliers/19.jpg'
        }
      ),
      new Image(19,
        {
          img: './assets/images/installations/particuliers/20.jpg'
        }
      ),
      new Image(20,
        {
          img: './assets/images/installations/particuliers/21.jpg'
        }
      ),
      new Image(21,
        {
          img: './assets/images/installations/particuliers/22.jpg'
        }
      ),
      new Image(22,
        {
          img: './assets/images/installations/particuliers/23.jpg'
        }
      ),
      new Image(23,
        {
          img: './assets/images/installations/particuliers/24.jpg'
        }
      ),
      new Image(24,
        {
          img: './assets/images/installations/particuliers/25.jpg'
        }
      ),
      new Image(25,
        {
          img: './assets/images/installations/particuliers/26.jpg'
        }
      ),
      new Image(26,
        {
          img: './assets/images/installations/particuliers/27.jpg'
        }
      ),
      new Image(27,
        {
          img: './assets/images/installations/particuliers/28.jpg'
        }
      ),
      new Image(28,
        {
          img: './assets/images/installations/particuliers/29.jpg'
        }
      ),
      new Image(29,
        {
          img: './assets/images/installations/particuliers/30.jpg'
        }
      ),
      new Image(30,
        {
          img: './assets/images/installations/particuliers/31.jpg'
        }
      ),
      new Image(31,
        {
          img: './assets/images/installations/particuliers/32.jpg'
        }
      ),
      new Image(32,
        {
          img: './assets/images/installations/particuliers/33.jpg'
        }
      ),
      new Image(33,
        {
          img: './assets/images/installations/particuliers/34.jpg'
        }
      ),
      new Image(34,
        {
          img: './assets/images/installations/particuliers/35.jpg'
        }
      ),
      new Image(35,
        {
          img: './assets/images/installations/particuliers/36.jpg'
        }
      ),
      new Image(36,
        {
          img: './assets/images/installations/particuliers/37.jpg'
        }
      ),
      new Image(37,
        {
          img: './assets/images/installations/particuliers/38.jpg'
        }
      ),
      new Image(38,
        {
          img: './assets/images/installations/particuliers/39.jpg'
        }
      ),
      new Image(39,
        {
          img: './assets/images/installations/particuliers/40.jpg'
        }
      ),
      new Image(40,
        {
          img: './assets/images/installations/particuliers/41.jpg'
        }
      )
    ];

    this.images.reverse();
  }
}
