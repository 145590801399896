import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { unescape, escape, isInteger } from 'lodash';
import { ClipboardService } from 'ngx-clipboard';

declare const jQuery: any;

@Component({
  selector: 'app-captable',
  templateUrl: './captable.component.html',
  styleUrls: ['./captable.component.scss']
})
export class CaptableComponent implements AfterViewInit, OnInit {
  
  selected = {
    code: 0,
    name: '_',
    rain: '_'
  };

  locked:             Boolean = false;
  step:               Number = 0;
  volumeCapte         = 0;
  wcNeed              = 0;
  mlNeed              = 0;
  need                = 0;
  tmpNeed             = 0;
  wcCheck:            Boolean = false;
  mlCheck:            Boolean = false;
  volumeNeeded        = 0;
  volumeNeededLiters  = 0;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  color_scheme = [
    ['#285FA4', 1000], // > 1000 mm
    ['#3277CC', 900], // > 900 mm
    ['#538DD5', 800], // > 800 mm
    ['#6E9FDC', 700], // > 700mm
    ['#98BBE6', 600], // > 600 mm
    ['#C0D5F0', 500], // > 500 mm
    ['#EAF1FA', 0] // < 500 mm;
  ];

  constructor(private _formBuilder: FormBuilder) { }

  ngAfterViewInit() {
    const that = this;

    let data = [
      ['Pyrénées-Atlantiques', 1218],
      ['Finistère',	1156],
      ['Hautes-Pyrénées',	1104],
      ['Landes',	1021],
      ['Tarn-et-Garonne', 761],
      ['Haute-Vienne', 962],
      ['Ariège', 922],
      ['Manche', 932],
      ['Côtes-d\'Armor', 757],
      ['Cantal', 1001],
      ['Savoie', 1109],
      ['Jura', 1091],
      ['Cher', 940],
      ['Haute-Marne', 851],
      ['Doubs', 1079],
      ['Isère', 998],
      ['Loire-Atlantique', 791],
      ['Creuse', 858],
      ['Morbihan', 803],
      ['Gironde', 785],
      ['Ain', 995],
      ['Territoire de Belfort', 968],
      ['Haute-Savoie', 1035],
      ['Dordogne', 791],
      ['Haute-Garonne', 798],
      ['Haute-Saône', 983],
      ['Vosges', 858],
      ['Corrèze', 865],
      ['Ardennes', 821],
      ['Vendée', 843],
      ['Lozère', 996],
      ['Ardèche', 955],
      ['Gers', 737],
      ['Deux-Sèvres', 791],
      ['Lot', 815],
      ['Charente-Maritime', 769],
      ['Charente', 809],
      ['Aveyron', 802],
      ['Tarn', 695],
      ['Calvados', 727],
      ['Pas-de-Calais', 776],
      ['Ille-et-Vilaine', 699],
      ['Seine-Maritime', 769],
      ['Maine-et-Loire', 702],
      ['Lot-et-Garonne', 696],
      ['Hautes-Alpes', 746],
      ['Aude', 627],
      ['Saône-et-Loire', 850],
      ['Meuse', 748],
      ['Gard', 1036],
      ['Mayenne', 738],
      ['Haute-Corse', 781],
      ['Somme', 720],
      ['Meurthe-et-Moselle', 730],
      ['Drôme', 870],
      ['Alpes-Maritimes', 814],
      ['Orne', 718],
      ['Pyrénées-Orientales', 592],
      ['Nord', 724],
      ['Côte-d\'Or', 751],
      ['Indre', 703],
      ['Rhône', 787],
      ['Moselle', 681],
      ['Sarthe', 694],
      ['Eure', 669],
      ['Paris', 636],
      ['Corse-du-Sud', 705],
      ['Vienne', 712],
      ['Val-de-Marne', 640],
      ['Allier', 760],
      ['Indre-et-Loire', 638],
      ['Haute-Loire', 726],
      ['Nièvre', 743],
      ['Seine-et-Marne', 625],
      ['Alpes-de-Haute-Provence', 756],
      ['Aube', 672],
      ['Hauts-de-Seine', 630],
      ['Aisne', 644],
      ['Marne', 643],
      ['Essonne', 635],
      ['Loire', 722],
      ['Haut-Rhin', 699],
      ['Oise', 628],
      ['Seine-Saint-Denis', 615],
      ['Hérault', 757],
      ['Loir-et-Cher', 638],
      ['Yvelines', 613],
      ['Val-d\'Oise', 614],
      ['Loiret', 639],
      ['Yonne', 649],
      ['Eure-et-Loir', 618],
      ['Puy-de-Dôme', 578],
      ['Bas-Rhin', 616],
      ['Var', 821],
      ['Vaucluse', 650],
      ['Bouches-du-Rhône', 576],
      ['Martinique', 773],
      ['La Réunion', 773],
      ['Mayotte', 773],
      ['Guyane française', 773],
      ['Guadeloupe', 773]
    ];

    let join = [
      ['FR-28',	'Eure-et-Loir'],
      ['FR-29',	'Finistère'],
      ['FR-22',	'Côtes-d\'Armor'],
      ['FR-23',	'Creuse'],
      ['FR-21',	'Côte-d\'Or'],
      ['FR-26',	'Drôme'],
      ['FR-27',	'Eure'],
      ['FR-24',	'Dordogne'],
      ['FR-25',	'Doubs'],
      ['FR-MQ',	'Martinique'], // ?
      ['FR-94',	'Val-de-Marne'],
      ['FR-93',	'Seine-Saint-Denis'],
      ['FR-92',	'Hauts-de-Seine'],
      ['FR-91',	'Essonne'],
      ['FR-90',	'Territoire de Belfort'],
      ['FR-17',	'Charente-Maritime'],
      ['FR-16',	'Charente'],
      ['FR-15',	'Cantal'],
      ['FR-14',	'Calvados'],
      ['FR-13',	'Bouches-du-Rhône'],
      ['FR-12',	'Aveyron'],
      ['FR-11',	'Aude'],
      ['FR-10',	'Aube'],
      ['FR-2B',	'Haute-Corse'],
      ['FR-2A',	'Corse-du-Sud'],
      ['FR-19',	'Corrèze'],
      ['FR-18',	'Cher'],
      ['FR-88',	'Vosges'],
      ['FR-89',	'Yonne'],
      ['FR-80',	'Somme'],
      ['FR-81',	'Tarn'],
      ['FR-82',	'Tarn-et-Garonne'],
      ['FR-83',	'Var'],
      ['FR-84',	'Vaucluse'],
      ['FR-85',	'Vendée'],
      ['FR-86',	'Vienne'],
      ['FR-87',	'Haute-Vienne'],
      ['FR-01',	'Ain'],
      ['FR-02',	'Aisne'],
      ['FR-03',	'Allier'],
      ['FR-04',	'Alpes-de-Haute-Provence'],
      ['FR-05',	'Hautes-Alpes'],
      ['FR-06',	'Alpes-Maritimes'],
      ['FR-07',	'Ardèche'],
      ['FR-08',	'Ardennes'],
      ['FR-09',	'Ariège'],
      ['FR-RE',	'La Réunion'],
      ['FR-75',	'Paris'],
      ['FR-74',	'Haute-Savoie'],
      ['FR-77',	'Seine-et-Marne'],
      ['FR-76',	'Seine-Maritime'],
      ['FR-71',	'Saône-et-Loire'],
      ['FR-70',	'Haute-Saône'],
      ['FR-73',	'Savoie'],
      ['FR-72',	'Sarthe'],
      ['FR-79',	'Deux-Sèvres'],
      ['FR-78',	'Yvelines'],
      ['FR-YT',	'Mayotte'],
      ['FR-66',	'Pyrénées-Orientales'],
      ['FR-67',	'Bas-Rhin'],
      ['FR-64',	'Pyrénées-Atlantiques'],
      ['FR-65',	'Hautes-Pyrénées'],
      ['FR-62',	'Pas-de-Calais'],
      ['FR-63',	'Puy-de-Dôme'],
      ['FR-60',	'Oise'],
      ['FR-61',	'Orne'],
      ['FR-68',	'Haut-Rhin'],
      ['FR-69',	'Rhône'],
      ['FR-53',	'Mayenne'],
      ['FR-52',	'Haute-Marne'],
      ['FR-51',	'Marne'],
      ['FR-50',	'Manche'],
      ['FR-57',	'Moselle'],
      ['FR-56',	'Morbihan'],
      ['FR-55',	'Meuse'],
      ['FR-54',	'Meurthe-et-Moselle'],
      ['FR-59',	'Nord'],
      ['FR-58',	'Nièvre'],
      ['FR-48',	'Lozère'],
      ['FR-49',	'Maine-et-Loire'],
      ['FR-44',	'Loire-Atlantique'],
      ['FR-45',	'Loiret'],
      ['FR-46',	'Lot'],
      ['FR-47',	'Lot-et-Garonne'],
      ['FR-40',	'Landes'],
      ['FR-41',	'Loir-et-Cher'],
      ['FR-42',	'Loire'],
      ['FR-43',	'Haute-Loire'],
      ['FR-95',	'Val-d\'Oise'],
      ['FR-GF',	'Guyane française'],
      ['FR-GP',	'Guadeloupe'],
      ['FR-39',	'Jura'],
      ['FR-38',	'Isère'],
      ['FR-31',	'Haute-Garonne'],
      ['FR-30',	'Gard'],
      ['FR-33',	'Gironde'],
      ['FR-32',	'Gers'],
      ['FR-35',	'Ille-et-Vilaine'],
      ['FR-34',	'Hérault'],
      ['FR-37',	'Indre-et-Loire'],
      ['FR-36',	'Indre']
    ];

    let joined = [];

    // tslint:disable-next-line:forin
    for (let i in data) {
      for (let j in join) {
        if (data[i][0] === join[j][1]) {
          joined.push([join[j][0], join[j][1], data[i][1]]);
        }
      }
    }

    let colors = {};

    // tslint:disable-next-line:forin
    for (let i in joined) {
      for (let j in this.color_scheme) {
        if (joined[i][2] >= this.color_scheme[j][1]) {
          colors[joined[i][0]] = this.color_scheme[j][0];
          break;
        }
      } 
    }

    let map;
    map = new jQuery('#vmap').vectorMap({
        map: 'fr_metro',
        hoverOpacity: 0.5,
        hoverColor: 'false',
        backgroundColor: 'white',
        regionStyle: {
          initial: {
            fill: '#b7bdc3',
            scaleColors: ['#b7bdc3', '#a2aaad'],
            'fill-opacity': 1
          },
          hover: { 'fill-opacity': 0.8 },
          selected: { fill: '#019875' },
          selectedHover: { fill: '#26a65b' }
      },
        series: {
          regions: [{
            values: colors,
            attribute: 'fill'
          }]
        },
        borderColor: '#000000',
        selectedColor: '#079e20',
        enableZoom: false,
        zoomOnScroll: false,
        regionsSelectable: true,
        regionsSelectableOne: true,
        showTooltip: true,
        onRegionClick: (event, code) => {		
          for (let i in joined) {
            if (joined[i][0] === code) { that.setSelectedDept(joined[i], code); }
          }
        },
        onRegionOver: (e, code) => {
          if (colors.hasOwnProperty(code)) { document.body.style.cursor = 'pointer'; }
        },
        onRegionOut: (e, code) => { document.body.style.cursor = 'default'; },
    });
  }

  ngOnInit() { 
    this.firstFormGroup = this._formBuilder.group({
      toitureSurfaceCtrl: ['', Validators.required],
      toitureTypeCtrl: ['', Validators.required],
      selectedDCtrl: [0, Validators.min(1)]
    });

    this.secondFormGroup = this._formBuilder.group({
      arrosageCtrl: [0, Validators.min(0)],
      nettoyageCtrl: [0, Validators.min(0)],
      autreCtrl: [0, Validators.min(0)],
      wcCapacityCtrl: [0, Validators.min(0)],
      numberCtrl: [0, Validators.min(0)],
      daysCtrl: [21, Validators.min(0)],
      peopleCtrl: [1, Validators.min(1)],
    });
  }

  setSelectedDept(data, code) {
    this.selected.code = code;
    this.selected.name = data[1] + ' - ' + data[0].split('-')[1];
    this.selected.rain = data[2];
    this.firstFormGroup.get('selectedDCtrl').setValue(data[0].split('-')[1]);
  }

  simulate(rain) {
    const nbPeople = this.secondFormGroup.get('peopleCtrl').value;

    this.volumeCapte = this.firstFormGroup.get('toitureSurfaceCtrl').value
                      * rain
                      * parseFloat(this.firstFormGroup.get('toitureTypeCtrl').value)
                      / 1000;

    this.volumeCapte = this.roundTo2(this.volumeCapte);

    // this.wcNeed = 2.5 * this.secondFormGroup.get('wcCapacityCtrl').value / 1000
    //               * this.secondFormGroup.get('numberCtrl').value
    //               * this.secondFormGroup.get('daysCtrl').value;

    // this.need = this.wcNeed

    this.wcNeed = 8.8 * nbPeople;
    this.mlNeed = 3.7 * nbPeople;
    this.tmpNeed = this.secondFormGroup.get('arrosageCtrl').value
                  + this.secondFormGroup.get('nettoyageCtrl').value
                  + this.secondFormGroup.get('autreCtrl').value;

    this.need = this.tmpNeed + (this.wcCheck ? this.wcNeed : 0) + (this.mlCheck ? this.mlNeed : 0);

    this.need = this.roundTo2(this.need);

    const av = (this.volumeCapte + this.need) / 2;

    this.volumeNeeded = av * this.secondFormGroup.get('daysCtrl').value / 365;
    this.volumeNeeded = this.roundTo2(this.volumeNeeded);

    this.volumeNeededLiters = this.roundTo2(this.volumeNeeded * 1000);
  }

  roundTo2(number) {
    return Math.round(number * 100 + Number.EPSILON ) / 100;
  }
}
