import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fuse-produit-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  equiped = [];

  constructor() { }

  ngOnInit() {
    this.equiped = [
      {name: 'Principauté de Monaco', where: '96 000 Litres le long d\'un parking'},
      {name: 'Direction de l\'Aménagement Urbain', where: 'Monaco'},
      {name: 'Mairie de Le Cannet des Maures', where: 'dept. 83'},
      {name: 'Ecole Léon Lagarde', where: 'dept. 34'},
      {name: 'Ecole d\'Abeilhans', where: 'dept. 34'},
      {name: 'Mairie de Menetrol', where: 'dept. 63'},
      {name: 'Mairie de Cosnac', where: 'dept. 19'},
      {name: 'Mairie de Tournus', where: 'dept. 71'},
      {name: 'Mairie de Dardilly', where: 'dept. 69'},
      {name: 'Mairie de Veneux-les-Sablons', where: 'dept. 77'},
      {name: 'Mairie de Montmorency', where: 'dept. 95'},
      {name: 'Serres Municipales', where: 'dept. 44'},
      {name: 'Mairie d\'Oullins', where: 'Parc de Sanzy dept. 69'},
      {name: 'Mairie de la Motte Servolex', where: 'Parc de Sanzy dept. 73'},
      {name: 'Mairie de Fleury-les Aubrais', where: 'Parc de Sanzy dept. 45'},
      {name: 'Mairie de Montmorency', where: 'dept. 95'},
      {name: 'Service technique de Marolles en Brie', where: 'dept. 94'},
      {name: 'Laboratoire de Physique Subatomique', where: 'Université Grenoble Alpes'},
      {name: 'Entreprise Abri Services', where: 'dept. 44'},
      {name: 'Jardins partagés', where: 'Sur toute la France'},
      {name: 'Et bien d\'autres encore !', where: ''},

    ];
  }

}
