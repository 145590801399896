import { NgModule } from '@angular/core';
import { CaracteristiquesComponent } from './caracteristiques.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

const routes = [{
  path: 'produit/caracteristiques',
  component: CaracteristiquesComponent
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    FuseSharedModule
  ],
  declarations: [CaracteristiquesComponent],
  exports: [CaracteristiquesComponent]
})
export class CaracteristiquesModule { }
