import { NgModule } from '@angular/core';
import { ParticuliersComponent } from './particuliers.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatGridListModule } from '@angular/material';
import 'hammerjs';
import 'mousetrap';
import { ModalGalleryModule } from 'angular-modal-gallery';
import { NgPipesModule } from 'ngx-pipes';

const routes = [{
  path: 'installations/particuliers',
  component: ParticuliersComponent
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ModalGalleryModule.forRoot(),
    FuseSharedModule,
    MatGridListModule,
    NgPipesModule
  ],
  declarations: [ParticuliersComponent],
  exports: [ParticuliersComponent]
})
export class ParticuliersModule { }
